import React, { Component } from 'react';

class Line extends Component {
	render(){

		const dx = this.props.x1-this.props.x0;
		const dy = this.props.y1-this.props.y0;

		const angle = Math.atan2(dy, dx);
		const length = Math.sqrt(dx * dx + dy * dy);

		return <div className="line" style={{
			"left": this.props.x0,
			"top": this.props.y0,
			"width": length,
			"height": 1,
			"transform": `rotate(${angle}rad)`,
		}}/>

	}
}

export default Line

import React, { Component } from 'react';

import QRCode from 'qrcode.react';


import {___} from "../translate"

import PlayIcon from "../images/btn_play.png"

import { Swiper, SwiperSlide } from 'swiper/react';


import AppContext from "../context"
import {
  LANGUAGE_SE, PAGE_START, PAGE_SUBCATEGORY, PAGE_PRODUCTS,
  PAGE_VEHICLE_LICENSE, PAGE_TO_PATH, MODE_APP, MODE_WEB, MODE_KIOSK, MODE_WEB_KIOSK
} from "../constants"



// import Modal from "../components/modal"

import ImportantIcon from "../images/products/important.png"



class ProductDetails extends Component {
	static contextType = AppContext;

	constructor(props) {
		super(props);

		this.state = {
			"showModal": false,
		};
	}

	getProductImageLarge(product){
		if(! product.img){
			return <this.props.currentCategory.icon/>
		}else if(product.img.image) {
			return <img alt={product.title} src={product.img.image}/>

		}
	}


	componentDidUpdate(prevProps, prevState, snapshot){
		if (this.props.currentProduct !== prevProps.currentProduct) {

			if(this.detail_scroller){
			    this.detail_scroller.withIScroll(function(iScroll) {
			      iScroll.scrollTo(0,0)
	    		})
			}

		}
	}



	openModal(product){



	}

	renderProductLinkInformation(product){
		if(! product.link_text && ! product.link_crit){
			return null
		}

		return (<div className="important-vehicle-info">
			<p>
				<img alt="" src={ImportantIcon}/>
				<b>{___("Viktig information om ditt valda fordon")}</b>
			</p>
			{ product.link_text && <div dangerouslySetInnerHTML={{__html: product.link_text}}/> }
			{ product.link_text && product.link_crit && <br/> }
			{ product.link_crit && <span dangerouslySetInnerHTML={{__html: product.link_crit}}/> }
		</div>

		)

	}


	render() {
		let product = this.props.currentProduct

		if(! product){
			return null;
		}

		let url = `https://uqr.se/u/${product.url || ""}/`


		return (<>
			{this.state.showModal && <div className="product-modal">
				<div className="content">
					<button className="close" onClick={()=>{this.setState({"showModal":false})}}>×</button>
					<h1>{___("Montering")}</h1>

					{this.context.mode === MODE_APP ?
						<>
							<a className="btn btn-primary" href={url} target="_blank">{url}</a>
						</>
						:
						<>
							<p>{___("Scanna qr-koden med din smartphone för guide!")}</p>
							<QRCode size={250} value={url} />
							<p>{url}</p>
						</>
					}

				</div>
				<div className="backdrop" onClick={()=>{this.setState({"showModal":false})}}/>
			</div>
			}
			<div className="product-details d-flex">


					<div className="image">
						{this.getProductImageLarge(product)}
						{ product.recommended && <div className="product-recommended">{___("Rekommenderad")}</div> }
					</div>

					<div className='info'>

						{
								product.url &&  (
								<div className="video-button">
									<button className="btn btn-success" onClick={()=>this.setState({"showModal": true})}>
										<img alt="play" src={PlayIcon}/><strong>{___("Montering")}</strong>
									</button>
								</div>
							)
						}


						<div className="title"><h2>{product.title}</h2></div>
						<div className="sku">
							<strong>Art nr:</strong> {product.nr ? product.nr : product.sku_nr}
						</div>
						<div className="text">
							<div>
							{
								// TODO: test important text
							}
							{ product.important_text && ( <div stlye={{marginBottom:20}} dangerouslySetInnerHTML={{__html: product.important_text}}/> )}

							{ this.renderProductLinkInformation(product) }

							{ product.text && <div dangerouslySetInnerHTML={{__html: product.text}}/> }
							<br/>

							{ product.ean_nr && <div className="ean"><b>EAN:</b> {product.ean_nr}</div> }
							<br/>
							</div>
						</div>
					</div>

			</div>
			</>
		)
	}
}

export default ProductDetails


import qs from "qs"


export const parseHandofUrl = ()=>{
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  return params.a
}


const getValueFromCustomFields = (data, key)=>{
  if(data && data.custom_fields){
      const matching_field = data.custom_fields.find((field)=>field.key === key)
      if(matching_field){
        return matching_field.value
      }
  }
}

export const getDeviceIdAndToken = (data)=>{

  let deviceToken = getValueFromCustomFields(data, "token")
  if(! deviceToken){
    deviceToken = process.env.REACT_APP_DEVICE_TOKEN
  }

  let deviceId = getValueFromCustomFields(data, "device_id")
  if(! deviceId){
    deviceId = process.env.REACT_APP_DEVICE_ID
  }

  return {deviceId, deviceToken}

}


//
export const FORM_UUID = "7948C3FB68E3495CA57E08A986B4809C";

export const LANGUAGE_SE = "SE";
export const LANGUAGE_NO = "NO";
export const LANGUAGE_DK = "DK";
export const LANGUAGE_GB = "GB";

export const MODE_CARPARTS = "carparts";

export const MODE_KIOSK="kiosk"
export const MODE_APP="app"
export const MODE_WEB="web"
export const MODE_WEB_KIOSK="web-kiosk"

export const PAGE_START = "start";
export const PAGE_SUBCATEGORY = "subcategory"
export const PAGE_PRODUCTS = "products";
export const PAGE_VEHICLE_LICENSE = "vehicle-license";
// const PAGE_VEHICLE_MANUAL = "vehicle-manual";

export const PAGE_TO_PATH = {
  [PAGE_START]: "/",
  [PAGE_VEHICLE_LICENSE]: "/vehicle/license",
  [PAGE_SUBCATEGORY]: "/catalog/category",
  [PAGE_PRODUCTS]: "/catalog/products",
}

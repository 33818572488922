
import React from "react";

const Dropdown = ({label, children})=>{

  const wrapperRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if(!open){
      return
    }

    const handleClickOutside = (event)=> {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setOpen(false)
        }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };

  }, [wrapperRef, setOpen, open]);


  const openClass = open ? " show" : ""

  return <div className={"dropdown"+openClass}>
    <button className="btn btn-outline-dark dropdown-toggle" type="button" onClick={()=>{setOpen(!open)}}>
      {label}
    </button>

    <div className={"dropdown-menu" +openClass} ref={wrapperRef} onClick={()=>{setOpen(false)}}>
      {children}
    </div>


  </div>

}

export default Dropdown

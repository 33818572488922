
import React, { Component } from 'react';

import CarAnimation1 from "../images/subcategory/subcategory_car_1.png"
import CarAnimation2 from "../images/subcategory/subcategory_car_2.png"
import CarAnimation3 from "../images/subcategory/subcategory_car_3.png"
import CarAnimation4 from "../images/subcategory/subcategory_car_4.png"
import CarAnimation5 from "../images/subcategory/subcategory_car_5.png"
import CarAnimation6 from "../images/subcategory/subcategory_car_6.png"
import CarAnimation7 from "../images/subcategory/subcategory_car_7.png"
import CarAnimation8 from "../images/subcategory/subcategory_car_8.png"
import CarAnimation9 from "../images/subcategory/subcategory_car_9.png"
import CarAnimation10 from "../images/subcategory/subcategory_car_10.png"


const CarImages = [
	CarAnimation1,
	CarAnimation2,
	CarAnimation3,
	CarAnimation4,
	CarAnimation5,
	CarAnimation6,
	CarAnimation7,
	CarAnimation8,
	CarAnimation9,
	CarAnimation10,
]


const Car = ({style, carFront, setCarIsTurning})=>{

	const [currentCarFrame, setCurrentCarFrame] = React.useState(0)

	const currentCarFrameRef = React.useRef(0)
	const carAnimation = React.useRef()


	const updateAnimation = React.useCallback((carFront)=>{
		const newCarFrame = (carFront ? -1 : 1) + currentCarFrameRef.current

		if(newCarFrame < 0){
			currentCarFrameRef.current = 0
			setCurrentCarFrame(0)
			setCarIsTurning(false)
			clearInterval(carAnimation.current)

		}else if(newCarFrame >= CarImages.length){

			currentCarFrameRef.current = CarImages.length-1
			setCurrentCarFrame(currentCarFrameRef.current)

			setCarIsTurning(false)
			clearInterval(carAnimation.current)

		}else{
			currentCarFrameRef.current = newCarFrame
			setCurrentCarFrame(newCarFrame)
		}


	}, [])

	React.useEffect(()=>{
		setCarIsTurning(true)

		clearInterval(carAnimation.current)

		carAnimation.current = setInterval(()=>{
			updateAnimation(carFront)
		}, 20)

		return ()=>{
			clearInterval(carAnimation.current)
		}

	}, [carFront])



	return 	<div className="car" style={style}>
		{CarImages.map((image, index)=>{
			return <img key={index} src={image} alt="" style={{"opacity": index===currentCarFrame ? 1 : 0}}/>
		})}
		</div>


}


export default Car

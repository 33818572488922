
import React from "react"

import {VirtualKeyboard, AN, KEYBOARDS} from "./vehicle-license"
import axios from "axios"
import AppContext from "../context"

const Activation = ({})=>{
  const [code, setCode] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState("")


  const {setAuth, mode} = React.useContext(AppContext)


  const load = async ()=>{
    if(! code){
      setError("Please enter code")
      return
    }
    setLoading(true)
    setError("")


    try{

      let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/code-activation`, {activation_code: code, timestamp: new Date()})

      const deviceId = response.data.device_id
      const deviceToken = response.data.device_token

      localStorage.setItem("device_id", deviceId)
      localStorage.setItem("device_token", deviceToken)
      localStorage.setItem("activation_uuid", response.data.activation_uuid)

      const newAuth = {
        deviceId: deviceId,
        deviceToken: deviceToken
      }

      setAuth(newAuth)

    }catch(er){

      if(er.response && er.response.status === 400  && er.response.data && er.response.data.detail){
        setError(er.response.data.detail)

      }else{
        setError(er.toString())
      }
      setLoading(false)
    }


  }


  return <div className="find-vehicle">
    <h2>Aktivering</h2>
    <h4>Ange butiksnummer för att aktivera</h4>

    <div className="d-flex my-3 w-100 justify-content-center">
      <div className="border w-50 p-1 bg-white mr-2 d-flex">{code}</div>

      <button onClick={()=>{setCode("")}}className="btn btn-outline-dark ml-2" disabled={loading}>Rensa</button>
      <button onClick={()=>{load()}}className="btn btn-success ml-2 d-flex align-items-center" disabled={loading}>Aktivera {loading && <span className="spinner-border spinner-border-sm ml-2"/>}</button>

    </div>
    <div className="bg-danger text-light p-2 mb-2" style={{opacity: error ? 1 : 0 }}>{error || "-"}</div>

    <VirtualKeyboard keyboard={KEYBOARDS[AN]} keyboardClick={(value)=>{
      if(loading){
        return
      }
      setCode(code + value)
    }}/>

  </div>
}


export default Activation

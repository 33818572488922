
import React from "react"
import AppContext from "../context"
import service from "../service"

import {___} from "../translate"


const DEFAULT_ACCOUNT = process.env.REACT_APP_WEB_ACCOUNT || ""
const DEFAULT_USERNAME = process.env.REACT_APP_WEB_USERNAME || ""
const DEFAULT_PASSWORD = process.env.REACT_APP_WEB_PASSWORD || ""

const LoginPage = ({})=>{

  const [account, setAccount] = React.useState(DEFAULT_ACCOUNT)
  const [username, setUsername] = React.useState(DEFAULT_USERNAME)
  const [password, setPassword] = React.useState(DEFAULT_PASSWORD)
  const [error, setError] =  React.useState("")
  const [loading, setLoading] = React.useState(false)


  const {setAuth, mode} = React.useContext(AppContext)


  const loginWithUsername = async ()=>{

    if(loading){
      return
    }
    setLoading(true)
    setError()

    try {
      let response = await service.login({username, password, account, remember: 1})
      if(response.data.success){

        let newAuth = {
          userToken: response.data.token,
          userId: response.data.user_id
        }
        setAuth(newAuth)


      }else{
        setError(response.data.message)
      }

    }catch(er){
      setError(er.toString())
    }

    setLoading(false)

  }


  return <div className="container my-3">
    <div className="row justify-content-center">
      <div className="col-md-6 col-12">
        <h2>{___("Logga in")}</h2>

        {error && <div className="alert alert-danger">{error}</div>}

        <div className="form-group">
          <label>{___("Konto")}</label>
          <input type="text" name="account" className="form-control" value={account} onChange={(event)=>{setAccount(event.target.value)}}/>
        </div>


        <div className="form-group">
          <label>{___("Användarnamn")}</label>
          <input type="text" name="username" className="form-control" value={username} onChange={(event)=>{setUsername(event.target.value)}}/>
        </div>


        <div className="form-group">
          <label>{___("Lösenord")}</label>
          <input type="password" name="password" className="form-control" value={password} onChange={(event)=>{setPassword(event.target.value)}}/>
        </div>

        {
          // <div>Kom ihåg mig</div>
        }

        <button className="btn btn-primary btn-block mt-1" type="button" onClick={()=>{loginWithUsername()}}>
          {loading && <span className="spinner-border spinner-border-sm"/>}
          {___("Logga in")}
        </button>
        {

        //   <div className="d-flex">
        //     <button className="btn btn-warning ml-auto mt-5" type="button">{___("Glömt lösenord?")}</button>
        //   </div>
        }
      </div>
    </div>
  </div>
}


export default LoginPage

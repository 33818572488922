import axios from "axios"

import {generateJwt} from "./utils/auth"


// Web

const get_headers = (auth)=>{
  let headers = {
    // 'Accept': 'application/json',
    // 'Content-Type': 'application/json'
  }

  if(auth.jwtToken){
    headers['Authorization'] = `Bearer ${auth.jwtToken}`

  }else if(auth.userToken){
    headers["Authorization"] = `AUTH-TOKEN ${auth.userToken}`

  }else if(auth.deviceId && auth.deviceToken){
    headers['Authorization'] = `Bearer ${generateJwt(auth.deviceToken, auth.deviceId)}`

  }else {
    throw new Error("Missing authentication")
  }

  return headers

}

const serviceGet = async (auth, path, params)=>{
  const headers = get_headers(auth)

  return axios.get(`${process.env.REACT_APP_API_URL}${path}`, {
    withCredentials: true,
    headers: headers,
    params
  })


}

const login = async (data)=>{

  return axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, data, {withCredentials: true})

}

const logout = async (auth)=>{
  const headers = get_headers(auth)
  return axios.post(`${process.env.REACT_APP_API_URL}/api/auth/logout`, null, {headers, withCredentials: true})

}


const service = {get: serviceGet, login: login, logout}

export default service

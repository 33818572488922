import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from "@sentry/react";

import App from './app';

import {setup_fixtures} from "./fixtures"
import {MODE_APP, MODE_WEB, MODE_KIOSK, MODE_WEB_KIOSK} from "./constants"

require(`./styles/modes/${process.env.REACT_APP_MODE}.scss`)




if(process.env.NODE_ENV !== "development"){

  Sentry.init({
    dsn: "https://4bcd721d82634ba792272581feefff5f@o237378.ingest.sentry.io/5555327",
    autoSessionTracking: false,
    // integrations: [
    //   new Integrations.BrowserTracing(),
    // ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    // tracesSampleRate: 1.0,
  });

}



let bootstrapped = false;

const bootstrap = (props) => {
  bootstrapped = true;
  ReactDOM.render(<App {...props}/>, document.getElementById('root'));
}


const MODE = process.env.REACT_APP_MODE

if(MODE === MODE_KIOSK){

  function onMessage(event){
    var message = JSON.parse(event.data)

    if(message && message.type === "data"){
      if(! bootstrapped){
        bootstrap({data: message, mode: MODE_KIOSK})
      }
    }
  }

  window.addEventListener("message", onMessage, false);

  if(process.env.NODE_ENV === "development"){
    setup_fixtures()
  }


}else if(MODE === MODE_WEB || MODE === MODE_WEB_KIOSK || MODE === MODE_APP) {

  bootstrap({mode: MODE})

}else{
  console.error("unknown mode: " + MODE)
}

import React from "react"

import {

  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect
} from "react-router-dom";

import categories from "../categories"

import StartPage from "./start"
import VehicleLicensePage from "./vehicle-license"
import ProductsPage from "./products"
import SubCategoryPage from "./subcategory"
import LoginPage from "./login"
import Activation from "./activation"

import TopBar from "../components/top-bar"
import BottomBar from "../components/bottom-bar"
import VehicleInfo from "../components/current-vehicle"
import IdleTimer from "../components/idle-timer"


import {
  LANGUAGE_SE, PAGE_START, PAGE_SUBCATEGORY, PAGE_PRODUCTS,
  PAGE_VEHICLE_LICENSE, PAGE_TO_PATH, MODE_WEB, MODE_KIOSK, MODE_WEB_KIOSK
} from "../constants"

import AppContext from "../context"



export const AppPages = ({language})=>{

  const history = useHistory()
  const location = useLocation()

  const [currentVehicle, setCurrentVehicle] =  React.useState(null)
  const [currentCategory, setCurrentCategory] =  React.useState(null)
  const [currentSubcategory, setCurrentSubcategory] =  React.useState(null)


  const {user, mode, auth} = React.useContext(AppContext)




  const setCurrentPage = React.useCallback((page)=>{
    history.push(PAGE_TO_PATH[page])
  }, [history])

  const isStartPage = location.pathname === "/"

  const resetVehicle = React.useCallback(()=>{
    // Called from bottom bar
    setCurrentPage(PAGE_VEHICLE_LICENSE)
    setCurrentVehicle(null)

  }, [setCurrentPage])

  const home = React.useCallback(()=>{

    if(mode === MODE_KIOSK){
      window.webViewBridge.send('area-show', {})
    }

    // Called from bottom bar
    setCurrentPage(PAGE_START)
    setCurrentCategory(null)
    setCurrentSubcategory(null)
    setCurrentVehicle(null)

  }, [mode, setCurrentPage])


  const selectSubcategory = React.useCallback((subcategory)=>{
    // Called from product page to choose another subcategory
    setCurrentSubcategory(subcategory)

    setCurrentPage(subcategory ? PAGE_PRODUCTS : PAGE_SUBCATEGORY)
  }, [setCurrentPage])


  const selectCategory = React.useCallback((category)=>{

    // Called from bottom bar or start
    if(! category){
      home()
      return
    }

    if(mode === MODE_KIOSK){
      window.webViewBridge.send('area-hide', {})
    }


    let page;

    if(! currentVehicle){
      page = PAGE_VEHICLE_LICENSE;

    }else if(category.subcategories){
      page = PAGE_SUBCATEGORY;

    }else{
      page = PAGE_PRODUCTS;
    }

    setCurrentCategory(category)
    setCurrentSubcategory(null)

    setCurrentPage(page)

  }, [currentVehicle, home, mode, setCurrentPage])


  const selectVehicle = React.useCallback((vehicle)=>{
    vehicle.form_label = `${vehicle.manufacturer}-${vehicle.model}-${vehicle.name} (${vehicle.id})`
    // window.tm.send({"action": "vehicleFound", "car": vehicle.form_label}, FORM_UUID)

    // Called from vehicle views
    let page
    if(currentCategory.subcategories){
      page = PAGE_SUBCATEGORY
    }else {
      page = PAGE_PRODUCTS
    }

    setCurrentVehicle(vehicle)
    setCurrentPage(page)

  }, [currentCategory, setCurrentPage])


  const activeCategories = React.useMemo(()=>{

    if(! user){
      return []
    }

    const activeCategories = []
    for(let category of categories){
      if (user.active_categories[category.id]){
        activeCategories.push(category)
      }
    }

    return activeCategories


  }, [user])



  const updateAreaSize = React.useCallback((size)=>{

    // Kiosk
    if(mode === MODE_KIOSK){
      window.webViewBridge.send('area-size', size)
    }
  }, [mode])



  if(mode === MODE_WEB_KIOSK && ! auth.deviceId){
    return <div className="splash"><Activation/></div>
  }



  return (
    <IdleTimer resetIntervall={(mode === MODE_KIOSK || mode === MODE_WEB_KIOSK) ? 120 : 0} onReset={home}>

      <div className={`app mode-${mode}`}>

        { mode === MODE_WEB && <TopBar resetVehicle={resetVehicle} setCurrentPage={setCurrentPage}/> }

        {
          currentVehicle && <VehicleInfo currentVehicle={currentVehicle} resetVehicle={resetVehicle} language={language}/>
        }


        <Switch>

          <Route path="/vehicle/license">
            {!currentCategory ?
              <Redirect to="/" /> :
              <VehicleLicensePage language={language} selectVehicle={selectVehicle}/>
            }
          </Route>

          <Route path="/catalog/category">
            {(! currentCategory || !currentVehicle) ?
              <Redirect to="/" /> :
              (
                currentCategory.subcategories ?
                  <SubCategoryPage categories={activeCategories} currentCategory={currentCategory} currentSubcategory={currentSubcategory} selectSubcategory={selectSubcategory} selectCategory={selectCategory}/> :
                  <Redirect to="/catalog/products" />
              )
            }
          </Route>

          <Route path="/catalog/products">
            {(! currentCategory || !currentVehicle) ?
              <Redirect to="/" /> :
                (
                  currentCategory.subcategories && !currentSubcategory ?
                  <Redirect to="/catalog/category" /> :
                  <ProductsPage currentCategory={currentCategory} currentSubcategory={currentSubcategory} currentVehicle={currentVehicle} selectSubcategory={selectSubcategory} selectCategory={selectCategory} categories={activeCategories}/>
                )
            }
          </Route>

          <Route path="/">
            { (mode === MODE_WEB && !auth.userId ) ?
              <LoginPage/> :
              <StartPage selectCategory={selectCategory} categories={activeCategories} updateAreaSize={updateAreaSize}/>
            }
          </Route>

        </Switch>


        {
          // Bottom bar
          !isStartPage && mode !== MODE_WEB && (<BottomBar
            categories={activeCategories}
            currentCategory={currentCategory}
            currentVehicle={currentVehicle}
            selectCategory={selectCategory}
            resetVehicle={resetVehicle}

          />)
        }

      </div>
    </IdleTimer>
  )

}

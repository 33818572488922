


import HmacSHA1 from 'crypto-js/hmac-sha1';
import HmacSHA256 from 'crypto-js/hmac-sha256';

import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import Hex from 'crypto-js/enc-hex';



//
// JWT token
//

function base64url(source) {
  // Encode in classical base64
  let encodedSource = Base64.stringify(source);

  // Remove padding equal characters
  encodedSource = encodedSource.replace(/=+$/, '');

  // Replace characters according to base64url specifications
  encodedSource = encodedSource.replace(/\+/g, '-');
  encodedSource = encodedSource.replace(/\//g, '_');

  return encodedSource;
}


export function generateJwt(secret, screenId){

  const header = {
    "alg": "HS256",
    "typ": "JWT"
  };

  const stringifiedHeader = Utf8.parse(JSON.stringify(header));
  const encodedHeader = base64url(stringifiedHeader);

  // exp
  // iat


  const JWT_EXPIRES = 60*60 // 1 hour

  const now = new Date()
  const timestamp = Math.round(now.getTime() / 1000)

  const data = {
      "exp": timestamp + JWT_EXPIRES,
      "id": screenId
  }

  const stringifiedData = Utf8.parse(JSON.stringify(data));
  const encodedData = base64url(stringifiedData);

  const token = encodedHeader + "." + encodedData;


  const signature = HmacSHA256(token, secret);
  const encodedSignature = base64url(signature);

  return token + "." + encodedSignature;

}




//
// TOTP token for hand of
//



function truncate(data) {
  const startByte = parseInt("0x" + data.substring(39, 40));
  return parseInt("0x" + data.substring(startByte * 2, startByte * 2 + 8));
}

function generateHOTP(secretKey, timeStep) {

  // Timestamp
  const currentTimestamp = Math.ceil((new Date()).getTime() / 1000); // round down
  const timeCounter = Math.floor(currentTimestamp / timeStep); // round up

  // Zero padded hex
  let timeCounterHex = timeCounter.toString(16)
  while (timeCounterHex.length < 16) timeCounterHex = "0" + timeCounterHex

  const hmacHash = String(HmacSHA1(Hex.parse(timeCounterHex), secretKey))

  const totpToken = truncate(hmacHash) & 0x7FFFFFFF;

  return totpToken
}


export function generateTotp(secret) {

  const timeStep = 30; // in seconds
  const digitsInValue = 6;

  const totpToken = generateHOTP(secret, timeStep)

  const totpTokenValue = totpToken % Math.pow(10, digitsInValue)

  return totpTokenValue
}

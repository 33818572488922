import React from "react"

import AppContext from "../context"
import service from "../service"
import {___} from "../translate"


const TopBar = ({resetVehicle, setCurrentPage})=>{

    const {auth, user} = React.useContext(AppContext)
    const [show, setShow] = React.useState(false)

    return <nav className="navbar navbar-dark bg-dark navbar-expand-md">
      <div className="container">
        <a className="navbar-brand" href="#" onClick={(event)=>{
          event.preventDefault()
          resetVehicle()
          setCurrentPage("start")
        }}>
        {
          <img src="/logo_sg.png" width="50" height="50" alt="logo"/>
        }
          Smartguide Online
        </a>
        <button className="navbar-toggler" type="button" onClick={()=>{setShow(!show)}}>
          <span className="navbar-toggler-icon"></span>
        </button>

        {user &&
          <div className={`collapse navbar-collapse ${show ? "show" : ""}`}>
            <div className="navbar-nav">
              <a className="nav-item nav-link active" href="#" onClick={(event)=>{
                event.preventDefault()
                // resetVehicle()
                setCurrentPage("start")
              }}><strong>{___("Start")}</strong></a>
              <a className="nav-item nav-link active" href="#" onClick={(event)=>{
                event.preventDefault()
                resetVehicle()

              }}><strong>{___("Ny sökning")}</strong></a>

            </div>
            <div className="navbar-nav ml-auto text-light align-items-center">
              {user.username} /
              <a className="nav-item nav-link ml-auto" href="#" onClick={async (event)=>{
                event.preventDefault()
                try {
                  const response = await service.logout(auth)
                }catch(er){
                  console.log(er.response.data)
                }

                setTimeout(()=>{
                  window.location.reload()
                }, 100)

              }}>{___("Logga ut")}</a>
            </div>

          </div>
        }

      </div>
    </nav>

}



export default TopBar

import React, { Component } from 'react';

import ProductDetails from "./product-details"

import {MODE_APP, MODE_WEB, MODE_KIOSK} from "../constants"

import {___} from "../translate"

import service from "../service"

import {OIL_CATEGORY_ID, ANTIFREEZE_CATEGORY_ID} from "../categories"
import {get_attribute_display, get_attributes_html, get_category_form_label} from "../utils/attributes"

import AppContext from "../context"

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import Dropdown from "../components/dropdown"

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';


// install Swiper components
SwiperCore.use([Navigation, Pagination, A11y]);



const StatusMessage = ({error, products, currentProduct})=>{

  if(error){
    return (<span className="products-error">
            {___("Ett oväntat fel har inträffat.")}<br/>
            {___("Vänligen försök igen eller kontakta personal.")}
        </span>)
  }

  if(products.length < 1) {
      return (<span>
          {___("Inga produkter finns i denna kategori för din bil.")}<br/>
          {___('Kontakta personal för ytterligare hjälp att hitta rätt produkt.')}
      </span>)
  }

  if(! currentProduct){
      return <span>{___('Välj produkt')}</span>
  }

}


const ResetSubcategoryButton = (props)=>{
  if(! props.currentCategory.subcategories){
    return null
  }
  return (
      <button onClick={()=>props.selectSubcategory(null)} className='btn btn-dark'>
          {___(props.currentCategory.subcategory_btn)}
      </button>
  )
}
const ProductFilterButtons = (props)=> {
  return (<div className="product-filters">


      <div className="filter-buttons">
        <div className="mb-2">
          <h3>{___('Välj det alternativ som stämmer för din bil')}</h3>
        </div>
          {
            props.filters.map((filterLabel, index)=>{
                return (<button key={index} className="btn btn-warning"

                          onClick={()=>{props.filterProducts(filterLabel)}}
                          dangerouslySetInnerHTML={{__html: filterLabel}}>

                </button>)
            })
          }
      </div>
  </div>)
}




const itemsPerPage = 4



const Products = ({currentCategory, currentSubcategory, currentVehicle, selectSubcategory, categories, selectCategory})=>{

  const [currentProduct, setCurrentProduct] =  React.useState(null)
  const [loading, setLoading] =  React.useState(true)

  const [products, setProducts] =  React.useState([])
  const [filters, setFilters] =  React.useState([])
  const [currentFilter, setCurrentFilter] =  React.useState(null)
  const [error, setError] =  React.useState(false)
  const [pages, setPages] =  React.useState(0)
  const [currentPage, setCurrentPage] =  React.useState(0)

  const [helpText, setHelpText] =  React.useState("")

  const {mode, auth} = React.useContext(AppContext)


  const showError = ()=>{
    setError(true)
    setLoading(false)
  }

  const reset = ()=>{
    setLoading(true)

    setError(false)
    setFilters([])
    setProducts([])
    setCurrentFilter(null)
    setCurrentProduct(null)
  }

  const selectProduct = (product)=>{
    setCurrentProduct(product)
      // window.tm.send({
      //         "action": "productDetails",
      //         "car":currentVehicle.form_label,
      //         "info": `${product.nr} - ${product.title}`
      //     }, carparts.FORM_UUID);

  }




  const updateProducts = (products)=>{

      let filters = []
      for(let product of products){
          // Combine all unique link_filter_multi_label
          if(product.link_filter_multi_label){
              if(filters.indexOf(product.link_filter_multi_label) < 0){
                  filters.push(product.link_filter_multi_label);
              }
          }
      }
      filters.sort()

      setLoading(false)
      setProducts(products)
      setFilters(filters)

      // window.tm.send({
      //         "action": products.length ? "productsCategory" : "productsEmpty",
      //         "car":currentVehicle.form_label,
      //         "info":get_category_form_label(currentCategory, currentSubcategory) + ` (${products.length})`
      //     }, carparts.FORM_UUID);

  }


  const filterProducts = (filter)=>{
      setCurrentFilter(filter)
  }


  const filteredProducts = React.useMemo(()=>{

    if(filters.length && !currentFilter){
      return []
    }

    return products.filter((product)=>{

        if(currentFilter){
            // If we have product filter, only show matching products or products without filtering
            let product_match_filter = product.link_filter_multi_label === currentFilter || product.link_filter_multi_label === "" || product.link_filter_multi_label === null
            return product_match_filter

        }else{
            return true
        }
    })

  }, [currentFilter, products, filters])




  React.useEffect(()=>{
    if(filteredProducts.length === 1){
      setCurrentProduct(filteredProducts[0])
      scrollPage()
    }else{
      setCurrentProduct(null)
    }

  }, [filteredProducts])




  const loadProducts = async ()=>{

    reset()


    const params = {
      vehicle: currentVehicle.id,
      nr: currentVehicle.nr,
      category: currentCategory.id,
    }

    if(currentSubcategory){
      params.subcategory = currentSubcategory.id
    }

    try{

      let response = await service.get(auth, "/api/products", params)

      updateProducts(response.data.products)

    }catch(error){
        showError()
    }
  }


  React.useEffect(()=>{

    loadProducts()
    if(currentCategory && currentVehicle){

    }
  }, [currentCategory, currentSubcategory, currentVehicle])

  const scrollPage = ()=>{
    setTimeout(()=>{
      let scroll = 400;
      window.scrollTo({top: scroll, behavior: "smooth"})
    }, 100)
  }

  const getProductImage = (product)=>{
    if(! product.img){
      return <currentCategory.icon/>
    }else if(product.img.thumbnail) {
      return <img alt={product.title} className="product-image" src={product.img.thumbnail}/>
    }
  }


  // Show products
  return (

          <div className="products">

              <div className="intro d-flex">

                {mode === MODE_WEB &&
                  <>
                    <Dropdown label={___(currentCategory.name)}>
                    {categories.map((category, index)=>{
                      return <button key={index} onClick={()=>{selectCategory(category)}} class="dropdown-item">{___(category.name)}</button>
                    })}
                    </Dropdown>

                    {currentSubcategory &&

                      <Dropdown label={___(currentSubcategory.name)}>
                      {currentCategory.subcategories.map((subcategory, index)=>{
                        return <button key={index} onClick={()=>{selectSubcategory(subcategory)}} class="dropdown-item">{___(subcategory.name)}</button>
                      })}
                      </Dropdown>
                    }
                  </>
                }

              {
                mode !== MODE_WEB && <h2>{___(currentCategory.name)} {currentSubcategory && <small>| {___(currentSubcategory.name)}</small>}</h2>
              }

                  <div className="ml-auto">
                    <ResetSubcategoryButton currentCategory={currentCategory} selectSubcategory={selectSubcategory} />
                    {currentFilter && <button  onClick={()=>{filterProducts(null)}} className='btn btn-warning'>{___("Visa alternativ")}</button>}
                  </div>
              </div>


              {filters.length > 0 && ! currentFilter &&
                <ProductFilterButtons selectSubcategory={selectSubcategory} currentCategory={currentCategory} filters={filters} filterProducts={filterProducts} />
              }


              {!loading && filteredProducts.length > 0 &&
              <div className="products-list">
              <Swiper
                spaceBetween={10}
                slidesPerView={2}
                navigation
                pagination={{ clickable: true }}

                breakpoints={{
                  576: {
                    slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 4,
                  },
                  1024: {
                    slidesPerView: 6,
                  },
                }}

                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
              >

                {
                  filteredProducts.map((product, index)=>{

                    let className = "product"

                    if(product === currentProduct){
                        className += " active"
                    }
                    return (
                        <SwiperSlide key={index} className="item" onClick={()=>{
                          selectProduct(product)
                          scrollPage()
                        }}>
                        <div className={className}>
                          <div className="image">
                            {getProductImage(product)}
                          </div>
                          <div className="title">{product.title}</div>
                        </div>
                    </SwiperSlide>)
                  })
                }

                </Swiper>
              </div>
              }


              <ProductDetails currentProduct={currentProduct}/>

              {! currentProduct && !loading && !(filters.length > 0 && ! currentFilter) &&
                  <div className='product-status'>
                    <StatusMessage error={error} products={products} currentProduct={currentProduct}/>
                  </div>
              }

              {
                loading && (<div className="spinner-container" ><span className="spinner spinner-border"/></div>)
              }


          </div>



  )


}

export default Products;

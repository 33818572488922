import React, { Component } from 'react';

import {LANGUAGE_SE, LANGUAGE_NO, LANGUAGE_DK, LANGUAGE_GB, MODE_APP, MODE_KIOSK, MODE_WEB, MODE_WEB_KIOSK} from "../constants"

import {format_year} from "../utils/attributes"
import {___} from "../translate"

import service from "../service"
import VehicleSelect from "./vehicle-multiple"

import AppContext from "../context"

// alphabet order
// const NUMERIC_KEYS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
// const ALPHA_KEYS = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]

// qwery
const NUMERIC_KEYS = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]
const ALPHA_KEYS = [
  ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
  ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
  ["Z", "X", "C", "V", "B", "N", "M"]
]

export const KEYBOARDS = {}

export const A = "alpha"
export const N = "numeric"
export const AN = "alphanumeric"

KEYBOARDS[A] = ALPHA_KEYS
KEYBOARDS[N] = [NUMERIC_KEYS]
KEYBOARDS[AN] = [NUMERIC_KEYS, ...ALPHA_KEYS]



export const VirtualKeyboard = ({keyboard, keyboardClick})=>{
  return <div className="virtual-keyboard">
    <ul>
      {
        keyboard.map((row, index)=>{
            return <div key={index}>
              {row.map((value, index)=>{
                return <li key={value} onClick={()=>{keyboardClick(value)}}>{value}</li>
              })}
            </div>

        })
      }
    </ul>
  </div>
}





const VehicleLicense = ({language, selectVehicle})=>{


  const {initialInput, inputTemplate} = React.useMemo(()=>{

    let inputTemplate, initialInput

    if(language === LANGUAGE_SE){

        if(process.env.NODE_ENV === "development"){
            initialInput = ["M", "H", "O", "2", "0", "0"]
        }else{
            initialInput = ["A", "B", "C", "1", "2", "3"]
        }

        inputTemplate = [A, A, A, N, N, AN]

    }else if(language === LANGUAGE_NO){
        if(process.env.NODE_ENV === "development"){
          initialInput = ["H", "B", "5", "7", "7", "7", "7"]

        }else {

          initialInput = ["A", "B", "1", "2", "3", "4", "5"]
        }
        inputTemplate = [A, A, N, N, N, N, N]

    }else if(language === LANGUAGE_GB){

        initialInput = ["A", "B", "1", "2", "A", "B", "C"]
        inputTemplate = [A, A, N, N, A, A, A]

    }else if(language === LANGUAGE_DK){

        initialInput = ["A", "B", "1", "2", "4", "5", "6"]
        inputTemplate = [A, A, N, N, N, N, N]

    }

    return {
      initialInput, inputTemplate
    }

  }, [language])


  // Native keyboard
  const initialInputText = initialInput.join("")
  const [currentInputText, setCurrentInputText] = React.useState(process.env.NODE_ENV === "development" ? initialInputText : "")

  // Virtual keyboard
  const [currentInput, setCurrentInput] = React.useState(initialInput)
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [keyboard, setKeyboard] = React.useState(KEYBOARDS[inputTemplate[0]])

  //
  const [error, setError] = React.useState("")
  const [loading, setLoading] = React.useState(false)

  //
  const [multipleVehicles, setMultipleVehicles] = React.useState()

  const {auth, mode} = React.useContext(AppContext)


  const showError = (message) => {
    setLoading(false)
    setError(___(message || 'Ditt registreringsnummer kunde inte hittas.'))
  }


  const submit = async()=>{

    let regno
    if(mode === MODE_APP || mode === MODE_WEB){
      regno = currentInputText
    }else{
      regno = currentInput.join("")

    }

    if(! regno){
      return
    }


    setLoading(true)
    setError()

    const params = {
      "nr": regno
    }

    try{

      let response = await service.get(auth, "/api/vehicles", params)

      let vehicles = response.data.vehicles

      if(! vehicles || !vehicles.length){
        showError()
        return
      }

      if(vehicles.length > 1){
        setMultipleVehicles(vehicles)
        setLoading(false)
      }else{

        var vehicle = vehicles[0]
        selectVehicle(vehicle)
      }


    }catch(error){
      console.log(error)

      if(error.response && error.response.status === 404){
        showError()
      }else if(error.response && error.response.status === 400){
        showError()
      }else if(error.response && error.response.status === 429){
        showError(___("To many requests, please try again later"))
      }else{
        showError(___("An unexpected error has occurred, please try again"))
      }

    }


  }


  const changeActiveIndex = (index)=>{
    setActiveIndex(index)
    setKeyboard(KEYBOARDS[inputTemplate[index]])
  }


  const keyboardClick = React.useCallback((value)=>{
    if(activeIndex === null){
        return;
    }

    let newInput = [...currentInput];
    newInput[activeIndex] = value;

    let nextActiveIndex = activeIndex + 1;
    if(nextActiveIndex >= inputTemplate.length){
        nextActiveIndex = null
    }

    setCurrentInput(newInput)
    setActiveIndex(nextActiveIndex)
    setKeyboard( KEYBOARDS[inputTemplate[nextActiveIndex]] || [])

  }, [currentInput, activeIndex])





  return (
    <div className="find-vehicle">

      {

        multipleVehicles && <div>
          <div className='intro d-flex'>
            <div>
              <h1>{___("Hitta din bilmodell")}</h1>
              <p>{___("Flera bilmodeller hittades, välj den som matchar din bil")}</p>
            </div>
            <div className="ml-auto">
              <button className='btn btn-dark' onClick={()=>{setMultipleVehicles()}}>Tillbaka</button>
            </div>
          </div>
          <VehicleSelect selectVehicle={selectVehicle} vehicles={multipleVehicles} language={language}/>
        </div>
      }

      {! multipleVehicles && (
        <div>

          <div className='intro'>
            <h1>{___("Hitta din bilmodell")}</h1>
            <p>{___("Ange bilens registreringsnummer genom att trycka här under.")}</p>
          </div>

          <div className="input-row">

            <div className={`input lang-${language.toLowerCase()}`}>

              <div className="country">
                <div className="inner"/>
              </div>

              {(mode === MODE_APP || mode === MODE_WEB) ?
                <input className="input" autoFocus={true} placeholder={initialInputText} value={currentInputText}
                  onKeyDown={(event)=>{
                    if (event.key === 'Enter') {
                      submit()
                    }
                  }}
                  onChange={(event)=>{
                  setCurrentInputText(event.target.value)
                }}/> :
                <div className="items">
                  {
                    currentInput.map((value, index)=>{
                      return <div key={index} className={`item ${index === activeIndex ? 'active' : ''}`} onClick={()=>{changeActiveIndex(index)}}>{value}</div>
                    })
                  }
                </div>
              }

            </div>

            <button onClick={()=>{submit()}} className='btn btn-primary'>{___("Gå vidare")}</button>
          </div>

          {error &&
            <div className='alert alert-warning'>{error}</div>
          }

          {(mode === MODE_KIOSK || mode === MODE_WEB_KIOSK) && <VirtualKeyboard keyboard={keyboard} keyboardClick={keyboardClick}/>}

        </div>
      )}


      {
        loading &&
          <div className="spinner-container"><span className="spinner-border"/></div>
      }

    </div>
  )

}



export default VehicleLicense;

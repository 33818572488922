import React from 'react';

import {___} from "../translate"

import {ReactComponent as CarSearch} from "../images/svg/car-search.svg"


const BottomBar = ({selectCategory, categories, currentCategory, currentVehicle, resetVehicle})=>{



	return (
		<div className="bottom-bar">
			<div onClick={()=>{selectCategory(null)}} className="home">
				{___("Hem")}
			</div>

			{ categories.map((category, index)=>{
					let className = `category ${category === currentCategory ? "active" : ""}`
					return (<div className={className} key={index} onClick={()=>{selectCategory(category)}}>
										<category.icon/>
										<span>{___(category.name)}</span>
									</div>)
				})
			}

			{
					<div className={`vehicle ${currentVehicle ? '' : "hide"}`} onClick={()=>{resetVehicle()}}>
						<CarSearch/>
						<span>{___("Välj bilmodell")}</span>
					</div>
			}
	</div>)

}

export default BottomBar;
